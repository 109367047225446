<template>
  <div>
    <input type="text" v-model="data.label" placeholder="Überschrift ..." />
    <select v-model="data.values">
      <optgroup label="Automatisch">
        <option value="auto">
          Maximal verfügbare freie Plätze automatisch berechnen
        </option>
      </optgroup>
      <optgroup label="Individuell">
        <option v-for="i in 100" :key="i" :value="i">
          Maximal {{ i }} Teilnehmer zulassen
        </option>
      </optgroup>
    </select>
    <textarea
      type="text"
      v-model="data.description"
      placeholder="Beschreibung (optional) ..."
    />
    <required />
    <columnWidth />
  </div>
</template>
<script>
export default {
  components: {
    columnWidth() {
      return import("./width.vue");
    },
    required() {
      return import("./required.vue");
    }
  },
  props: {
    data: Object
  },
  data() {
    return {};
  },
  watch: {
    "data.description"() {
      this.setSave();
    },
    "data.label"() {
      this.setSave();
    }
  },
  computed: {},
  mounted() {},
  methods: {
    setSave() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.form[this.$store.state.form.edit] = this.data;
      this.$store.commit("business/prefetch", data);
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables.sass'
input, textarea, select
  width: 100%
  padding: 10px
  box-sizing: border-box
  border: 1px solid $success
  margin-bottom: 10px
  &:focus
    outline: none
textarea
  height: 200px
</style>
